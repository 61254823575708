import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import HomeScreen from "./screens/HomeScreen";
import TellMeMoreScreen from "./screens/TellMeMoreScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";

import CookieConsent from "react-cookie-consent";

const App = () => {
  const [showThankYouModal, setShowThankYouModal] = useState(false);

  const handleShowModal = () => {
    setShowThankYouModal(true);
  };

  const handleHideModal = () => {
    setShowThankYouModal(false);
  };

  return (
    <>
      <Router>
        <Switch>
          <Route path="/privacy-policy" exact>
            <PrivacyPolicyScreen />
          </Route>
          <Route path="/tell-me-more" exact>
            <TellMeMoreScreen showModal={handleShowModal} />
          </Route>
          <Route path="*" exact>
            <HomeScreen
              modal={showThankYouModal}
              showModal={handleShowModal}
              hideModal={handleHideModal}
            />
          </Route>
        </Switch>
      </Router>

      <CookieConsent
        location="bottom"
        buttonText="Allow"
        cookieName="cookieConcent"
        style={{ background: "#2a4298", color: "#fff" }}
        buttonStyle={{ backgroundColor: "#fff", color: "#2a4298", fontSize: "14px" }}
        expires={365}
      >
        We use cookies to give you the best possible online experience. By using this website you agree to our use of cookies.
      </CookieConsent>
    </>
  );
};

export default App;
