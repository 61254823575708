import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import PayPal from "../components/PayPal";

const PayPalModal = (props) => {
  const history = useHistory();

  const [quantity, setQuantity] = useState(24);

  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      plan_id: "P-2RP734409H551724BMAWM2MA",
      quantity: quantity,
    });
  };

  const paypalOnError = (err) => {
    alert("Something went wrong! Please try again.");
    console.log("ERR: Payment Canceled!");
  };

  const paypalOnApprove = (data) => {
    axios
      .post("https://mowcf.org.za/api/authenticate/", {
        "client-id": "master",
        passphrase: "sa8yfub9basd8fb0as",
      })
      .then(function (response) {
        if (response.data.status === "success") {
          const token = response.data.token;

          axios
            .post("https://mowcf.org.za/api/donations/create/", {
              token: token,
              "visitor-id": props.visitor,
              "order-id": data.orderID,
              meals: quantity,
            })
            .then(function (response) {
              if (response.data.status === "success") {
                axios
                  .post("https://mowcf.org.za/api/authenticate/", {
                    "client-id": "master",
                    passphrase: "sa8yfub9basd8fb0as",
                  })
                  .then(function (response) {
                    if (response.data.status === "success") {
                      const token = response.data.token;

                      axios
                        .post(
                          "https://mowcf.org.za/api/visitors/update-donation/",
                          {
                            token: token,
                            "visitor-id": props.visitor,
                          }
                        )
                        .then(function (response) {
                          console.log("Visitor Updated!");
                        })
                        .catch(function (error) {
                          console.log("ERR: Visitor Not Updated!");
                        });
                    } else {
                      console.log("ERR:" + response.data.error);
                    }
                  })
                  .catch(function (error) {
                    console.log("ERR: Auth Failed!");
                  });

                props.hide();
                props.thankYouModal(true);
                history.push("/");
              } else {
                alert("Something went wrong! Please try again.");
                console.log("ERR: Unexpected Response!");
              }
            })
            .catch(function (error) {
              alert("Something went wrong! Please try again.");
              console.log("ERR: Payment not Stored!");
            });
        } else {
          alert("Something went wrong! Please try again.");
          console.log("ERR:" + response.data.error);
        }
      })
      .catch(function (error) {
        alert("Something went wrong! Please try again.");
        console.log("ERR: Auth Failed!");
      });
  };

  return (
    <Modal show={props.show} backdrop="static" centered onHide={props.hide}>
      <Modal.Header closeButton>
        <Modal.Title id="share-modal-title">DONATE</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-5">
        <Form.Group controlId="quantity">
          <Form.Label>
            How many meals per month would you like to donate?
          </Form.Label>
          <Form.Control
            type="number"
            value={quantity}
            onChange={(e) => {
              console.log(e);
              setQuantity(e.target.value);
            }}
            min={1}
          />
        </Form.Group>

        <PayPal
          amount="5"
          currency="USD"
          createSubscription={paypalSubscribe}
          onApprove={paypalOnApprove}
          catchError={paypalOnError}
          onError={paypalOnError}
          onCancel={paypalOnError}
        />

        <h1 className="my-5 text-center">OR</h1>
        <Button
          variant="outline-primary"
          block
          href="https://www.givengain.com/d/c/2403#amount"
        >
          DONATE VIA <img
            className="img-fluid text-center"
            width="100"
            height="100"
            src="/givengain-logo.png"
            alt="GivenGain Logo"
        />
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.hide}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PayPalModal;
