import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ShareModal = (props) => {
  return (
    <Modal show={props.show} backdrop="static" centered onHide={props.hide}>
      <Modal.Header closeButton>
        <Modal.Title id="share-modal-title">THANK YOU!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-justify">
          Warm greetings to you. Thank you for taking the plight of a struggling
          South African child into your heart. One of our public relations staff
          will contact you in the next few days to tell you more about the Meals
          on Wheels Miracle. Please take care till then.

          <br /><br />

          We would greatly appreciate your support in Liking & Sharing our Facebook Page! {" "}
          <a href="https://web.facebook.com/mowcsa" target="_blank">Meal On Wheels</a>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.hide}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareModal;
