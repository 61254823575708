import React from "react";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PrivacyPolicyScreen = () => {
  return (
    <Container fluid>
      <Row>
        <Col md={2}></Col>
        <Col md={8} className="p-3 p-lg-5">
          <div className="mb-5 d-flex justify-content-between align-items-center border-bottom">
            <h1>Privacy Policy</h1>

            <Link className="text-primary" to="/">
              Back to Home Page
            </Link>
          </div>

          <p className="text-justify">
            Your privacy is very important to us. Accordingly, we have developed
            this policy in order for you to underst and how we collect, use,
            communicate and disclose and make use of personal information, in
            accordance with the Electronic Communications and Transactions Act
            25 of 2002 and the Protection of Personal Information Act 4 of 2013
            (“POPI”). The following outlines our Privacy Policy.
          </p>

          <ul>
            <li className="mb-3">
              Before or at the time of collecting personal information, we will
              identify the purposes for which information is being collected.
              Personal information is processed mainly for the following
              purposes:
            </li>
            <li className="mb-3">
              We will collect, use and disclose personal information solely with
              the objective of fulfilling those purposes specified by us and for
              other compatible purposes, unless we obtain your consent of or as
              required by law.
            </li>
            <li className="mb-3">
              We will authorise third parties to use your personal information
              only to the extent necessary to perform services on our behalf or
              to comply with legal requirements and we strive to ensure that
              each user’s personal information is always protected.
            </li>
            <li className="mb-3">
              We will only retain personal information as long as necessary for
              the fulfilment of those purposes specified by us as well as for
              other compatible purposes, for purposes consented to by you or as
              required by law.
            </li>
            <li className="mb-3">
              We will collect personal information by lawful and fair means and,
              where appropriate, with your knowledge or consent.
            </li>
            <li className="mb-3">
              You will have the right to access, limit, oppose and delete your
              personal information, and to withdraw your consent to the
              processing of your personal information at any time.
            </li>
            <li className="mb-3">
              Personal information should be relevant to the purposes for which
              it is to be used, and, to the extent necessary for those purposes,
              should be accurate, complete, and up-todate. To this end you will
              have the right to access your personal information and to request
              that we rectify the information collected, in the event that it is
              incomplete or contains any errors.
            </li>
            <li className="mb-3">
              We will protect personal information by means of reasonable
              security safeguards against loss or theft, as well as unauthorized
              access, disclosure, copying, use or modification.
            </li>
            <li className="mb-3">
              We make use of cookies and shall provide you with the option to
              accept or deny the use of cookies.
            </li>
            <li className="mb-3">
              We will make information about our policies and practices relating
              to the management of personal information readily available to
              customers.
            </li>
            <li className="mb-3">
              We will ensure that any personal information transferred to a
              country outside of South Africa is afforded the same level of data
              protection under POPI. In such a case, we will ensure that,
              either:
              <ul>
                <li className="mb-3">
                  The country has a law that provides adequate protection; or
                </li>
                <li className="mb-3">
                  There is an agreement between ourselves and the receiver of
                  the personal information that provides adequate protection.
                </li>
              </ul>
            </li>
            <li className="mb-3">
              We will ensure that you will have the right to access, rectify,
              limit, oppose and delete personal information about yourself. You
              will also be allowed to withdraw your consent to the processing of
              your personal information at any time.
            </li>
          </ul>

          <p className="text-justify">
            We are committed to conducting our business in accordance with these
            principles in order to ensure that the confidentiality of personal
            information is protected and maintained.
          </p>

          <p className="text-justify m-0 p-0">
            By making use of this website, you concent:
          </p>
          <p className="text-justify m-0 p-0">
            <strong>1.</strong> to the processing of your personal information
            in accordance with the terms hereof.
          </p>
          <p className="text-justify m-0 p-0">
            <strong>2.</strong> that you are aware that in the event that your
            personal information is misused, you have the right to file a
            complaint with the Information Regulator at email:{" "}
            <a className="text-primary" href="mailto:infoirsa@justice.gov.za">
              infoirsa@justice.gov.za
            </a>
            .
          </p>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicyScreen;
