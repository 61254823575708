import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";

import cookie from "react-cookies";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import PayPalModal from "../components/PayPalModal";
import ShareModal from "../components/ShareModal";

const TellMeMoreScreen = (props) => {
  const history = useHistory();
  const refererQuery = new URLSearchParams(useLocation().search);

  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [donateModalShow, setDonateModalShow] = useState(false);
  const [shareModalShow, setShareModalShow] = useState(false);

  useEffect(() => {
    const visitorID = cookie.load("visitor");

    if (visitorID === undefined) {
      const referer = refererQuery.get("ref");
      let refererString = "";

      if (referer === "7qy21") {
        refererString = "google";
      } else if (referer === "9a9jq") {
        refererString = "facebook";
      } else if (referer === "918ns") {
        refererString = "instagram";
      } else if (referer === "0a1na") {
        refererString = "chatbot";
      } else if (referer === "a97av") {
        refererString = "linkedin";
      } else {
        refererString = "generic";
      }

      axios
        .post("https://mowcf.org.za/api/authenticate/", {
          "client-id": "master",
          passphrase: "sa8yfub9basd8fb0as",
        })
        .then(function (response) {
          if (response.data.status === "success") {
            const token = response.data.token;

            axios
              .post("https://mowcf.org.za/api/visitors/create/", {
                token: token,
                "referer-code": refererString,
              })
              .then(function (response) {
                if (response.data.status === "success") {
                  const newVisitorID = response.data.visitor;
                  cookie.save("visitor", newVisitorID, { path: "/" });
                }
              })
              .catch(function (error) {
                alert("Something went wrong! Please try again.");
                console.log("ERR: Visitor Not Created!");
              });
          } else {
            alert("Something went wrong! Please try again.");
            console.log("ERR:" + response.data.error);
          }
        })
        .catch(function (error) {
          alert("Something went wrong! Please try again.");
          console.log("ERR: Auth Failed!");
        });
    }
  }, []);

  const handleVisitorLeadSend = () => {
    const visitorID = cookie.load("visitor");

    axios
      .post("https://mowcf.org.za/api/authenticate/", {
        "client-id": "master",
        passphrase: "sa8yfub9basd8fb0as",
      })
      .then(function (response) {
        if (response.data.status === "success") {
          const token = response.data.token;

          axios
            .post("https://mowcf.org.za/api/visitors/update-lead/", {
              token: token,
              "visitor-id": visitorID,
            })
            .then(function (response) {
              console.log("Visitor Updated!");
            })
            .catch(function (error) {
              console.log("ERR: Visitor Not Updated!");
            });
        } else {
          console.log("ERR:" + response.data.error);
        }
      })
      .catch(function (error) {
        console.log("ERR: Auth Failed!");
      });
  };

  const handleVisitorDonateClick = () => {
    const visitorID = cookie.load("visitor");

    axios
      .post("https://mowcf.org.za/api/authenticate/", {
        "client-id": "master",
        passphrase: "sa8yfub9basd8fb0as",
      })
      .then(function (response) {
        if (response.data.status === "success") {
          const token = response.data.token;

          axios
            .post("https://mowcf.org.za/api/visitors/update-donate-click/", {
              token: token,
              "visitor-id": visitorID,
            })
            .then(function (response) {
              console.log("Visitor Updated!");
            })
            .catch(function (error) {
              console.log("ERR: Visitor Not Updated!");
            });
        } else {
          console.log("ERR:" + response.data.error);
        }
      })
      .catch(function (error) {
        console.log("ERR: Auth Failed!");
      });
  };

  const handleVisitorShareClick = () => {
    const visitorID = cookie.load("visitor");

    axios
      .post("https://mowcf.org.za/api/authenticate/", {
        "client-id": "master",
        passphrase: "sa8yfub9basd8fb0as",
      })
      .then(function (response) {
        if (response.data.status === "success") {
          const token = response.data.token;

          axios
            .post("https://mowcf.org.za/api/visitors/update-share-click/", {
              token: token,
              "visitor-id": visitorID,
            })
            .then(function (response) {
              console.log("Visitor Updated!");
            })
            .catch(function (error) {
              console.log("ERR: Visitor Not Updated!");
            });
        } else {
          console.log("ERR:" + response.data.error);
        }
      })
      .catch(function (error) {
        console.log("ERR: Auth Failed!");
      });
  };

  const handleDonateModalShow = () => {
    setDonateModalShow(true);
  };

  const handleDonateModalHide = () => {
    setDonateModalShow(false);
  };

  const handleShareModalShow = () => {
    setShareModalShow(true);
  };

  const handleShareModalHide = () => {
    setShareModalShow(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const visitorID = cookie.load("visitor");

    const data = {
      firstName: firstName,
      phoneNumber: phoneNumber,
    };

    axios
      .post("https://mowcf.org.za/api/authenticate/", {
        "client-id": "master",
        passphrase: "sa8yfub9basd8fb0as",
      })
      .then(function (response) {
        if (response.data.status === "success") {
          const token = response.data.token;

          axios
            .post("https://mowcf.org.za/api/leads/create/", {
              token: token,
              "visitor-id": visitorID,
              "first-name": data.firstName,
              "phone-number": data.phoneNumber,
            })
            .then(function (response) {
              handleVisitorLeadSend();
              setFirstName("");
              setPhoneNumber("");
              props.showModal(true);
              history.push("/");
            })
            .catch(function (error) {
              alert("Something went wrong! Please try again.");
              console.log("ERR: Lead not Stored!");
            });
        } else {
          alert("Something went wrong! Please try again.");
          console.log("ERR:" + response.data.error);
        }
      })
      .catch(function (error) {
        alert("Something went wrong! Please try again.");
        console.log("ERR: Auth Failed!");
      });
  };

  return (
    <Container fluid>
      <Row>
        <Col md={3}></Col>
        <Col md={6} className="p-3 p-lg-5 pb-lg-4">
          <div className="form-full">
            <img
              className="img-fluid mow-img"
              src="/Logo.png"
              alt="Meal on Wheels Logo"
            />
            <span className="mt-1 mb-4 logo-text-full text-center">
              Children's Fund
            </span>

            <h2 className="text-center">Tell Me More</h2>

            <Form className="mt-3" onSubmit={handleSubmit}>
              <Form.Group controlId="first-name">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="first-name"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  name=""
                  as={PhoneInput}
                  country={"za"}
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e);
                  }}
                />
              </Form.Group>

              <Button variant="primary" type="submit" block>
                SEND
              </Button>

              <p className="mt-4 text-center">
                We ensure your personal information is kept safe!
              </p>
              <div className="mb-4 text-center">
                <Link className="text-primary" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </div>
            </Form>

            <hr className="w-100 border border-light" />

            <Button
              variant="primary"
              onClick={() => {
                handleDonateModalShow();
                handleVisitorDonateClick();
              }}
              block
            >
              DONATE NOW
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                handleShareModalShow();
                handleVisitorShareClick();
              }}
              block
            >
              SHARE
            </Button>
            <Button variant="primary" as={Link} to="/" block>
              WATCH THE VIDEO
            </Button>
          </div>
        </Col>
        <Col md={3}></Col>
      </Row>

      <ShareModal
        show={shareModalShow}
        hide={handleShareModalHide}
        url="https://mowcf.org.za/tell-me-more"
      />

      <PayPalModal
        show={donateModalShow}
        hide={handleDonateModalHide}
        thankYouModal={props.showModal}
        visitor={cookie.load("visitor")}
      />
    </Container>
  );
};

export default TellMeMoreScreen;
