import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

import cookie from "react-cookies";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import PayPalModal from "../components/PayPalModal";
import ShareModal from "../components/ShareModal";
import ThankYouModal from "../components/ThankYouModal";

const HomeScreen = (props) => {
  const refererQuery = new URLSearchParams(useLocation().search);

  const [donateModalShow, setDonateModalShow] = useState(false);
  const [shareModalShow, setShareModalShow] = useState(false);

  useEffect(() => {
    const visitorID = cookie.load("visitor");

    if (visitorID === undefined) {
      const referer = refererQuery.get("ref");
      let refererString = "";

      if (referer === "7qy21") {
        refererString = "google";
      } else if (referer === "9a9jq") {
        refererString = "facebook";
      } else if (referer === "918ns") {
        refererString = "instagram";
      } else if (referer === "0a1na") {
        refererString = "chatbot";
      } else if (referer === "a97av") {
        refererString = "linkedin";
      } else {
        refererString = "generic";
      }

      axios
        .post("https://mowcf.org.za/api/authenticate/", {
          "client-id": "master",
          passphrase: "sa8yfub9basd8fb0as",
        })
        .then(function (response) {
          if (response.data.status === "success") {
            const token = response.data.token;

            axios
              .post("https://mowcf.org.za/api/visitors/create/", {
                token: token,
                "referer-code": refererString,
              })
              .then(function (response) {
                if (response.data.status === "success") {
                  const newVisitorID = response.data.visitor;
                  cookie.save("visitor", newVisitorID, { path: "/" });
                }
              })
              .catch(function (error) {
                alert("Something went wrong! Please try again.");
                console.log("ERR: Visitor Not Created!");
              });
          } else {
            alert("Something went wrong! Please try again.");
            console.log("ERR:" + response.data.error);
          }
        })
        .catch(function (error) {
          alert("Something went wrong! Please try again.");
          console.log("ERR: Auth Failed!");
        });
    }
  }, []);

  const handleVisitorDonateClick = () => {
    const visitorID = cookie.load("visitor");

    axios
      .post("https://mowcf.org.za/api/authenticate/", {
        "client-id": "master",
        passphrase: "sa8yfub9basd8fb0as",
      })
      .then(function (response) {
        if (response.data.status === "success") {
          const token = response.data.token;

          axios
            .post("https://mowcf.org.za/api/visitors/update-donate-click/", {
              token: token,
              "visitor-id": visitorID,
            })
            .then(function (response) {
              console.log("Visitor Updated!");
            })
            .catch(function (error) {
              console.log("ERR: Visitor Not Updated!");
            });
        } else {
          console.log("ERR:" + response.data.error);
        }
      })
      .catch(function (error) {
        console.log("ERR: Auth Failed!");
      });
  };

  const handleVisitorLeadClick = () => {
    const visitorID = cookie.load("visitor");

    axios
      .post("https://mowcf.org.za/api/authenticate/", {
        "client-id": "master",
        passphrase: "sa8yfub9basd8fb0as",
      })
      .then(function (response) {
        if (response.data.status === "success") {
          const token = response.data.token;

          axios
            .post("https://mowcf.org.za/api/visitors/update-lead-click/", {
              token: token,
              "visitor-id": visitorID,
            })
            .then(function (response) {
              console.log("Visitor Updated!");
            })
            .catch(function (error) {
              console.log("ERR: Visitor Not Updated!");
            });
        } else {
          console.log("ERR:" + response.data.error);
        }
      })
      .catch(function (error) {
        console.log("ERR: Auth Failed!");
      });
  };

  const handleVisitorShareClick = () => {
    const visitorID = cookie.load("visitor");

    axios
      .post("https://mowcf.org.za/api/authenticate/", {
        "client-id": "master",
        passphrase: "sa8yfub9basd8fb0as",
      })
      .then(function (response) {
        if (response.data.status === "success") {
          const token = response.data.token;

          axios
            .post("https://mowcf.org.za/api/visitors/update-share-click/", {
              token: token,
              "visitor-id": visitorID,
            })
            .then(function (response) {
              console.log("Visitor Updated!");
            })
            .catch(function (error) {
              console.log("ERR: Visitor Not Updated!");
            });
        } else {
          console.log("ERR:" + response.data.error);
        }
      })
      .catch(function (error) {
        console.log("ERR: Auth Failed!");
      });
  };

  const handleDonateModalShow = () => {
    setDonateModalShow(true);
  };

  const handleDonateModalHide = () => {
    setDonateModalShow(false);
  };

  const handleShareModalShow = () => {
    setShareModalShow(true);
  };

  const handleShareModalHide = () => {
    setShareModalShow(false);
  };

  return (
    <Container fluid>
      <Row>
        <Col md={2}></Col>
        <Col md={8} className="p-3 p-lg-5 pb-lg-4">
          <div className="text-center mb-5">
            <img
              className="img-fluid mow-img"
              src="/Logo.png"
              alt="Meal on Wheels Logo"
            />
          </div>

          <iframe
            className="video rounded"
            src="https://www.youtube.com/embed/sBRWy31G-AQ?rel=0?version=3&autoplay=1&controls=0&showinfo=0&loop=1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>

          <div className="row mb-4">
            <div className="col-md-4 mb-2 text-center">
              <Button
                variant="primary"
                onClick={() => {
                  handleDonateModalShow();
                  handleVisitorDonateClick();
                }}
                block
              >
                DONATE
              </Button>
            </div>

            <div className="col-md-4 mb-2">
              <Button
                variant="primary"
                as={Link}
                to="/tell-me-more"
                onClick={() => {
                  handleVisitorLeadClick();
                }}
                block
              >
                TELL ME MORE
              </Button>
            </div>

            <div className="col-md-4 mb-2">
              <Button
                variant="primary"
                onClick={() => {
                  handleShareModalShow();
                  handleVisitorShareClick();
                }}
                block
              >
                SHARE
              </Button>
            </div>
          </div>

          <hr />

          <div className="m-0 p-0 text-center">
            <img
              className="img-fluid paypal-img"
              src="/PayPal-Logo.png"
              alt="PayPal Logo"
            />
            <p className="mt-3 text-muted">
              All transactions are securely processed through the PayPal payment
              gateway! Giving you full control over the amount, billing date and
              cancellations.
            </p>
          </div>

          <hr />

          <div className="d-flex justify-content-between align-items-center">
            <p className="text-muted m-0 p-0">
              © Copyright 2021 Meals on Wheels Community Services
            </p>

            <div className="m-0 p-0 d-flex justify-content-center align-items-center">
              <img
                className="img-fluid poppi-img mr-1"
                src="/Popi.png"
                alt="Popi Logo"
              />
              <Link className="text-primary" to="/privacy-policy">
                Privacy Policy
              </Link>
            </div>
          </div>
        </Col>
        <Col md={2}></Col>
      </Row>

      <ShareModal
        show={shareModalShow}
        hide={handleShareModalHide}
        url="https://mowcf.org.za"
      />

      <PayPalModal
        show={donateModalShow}
        hide={handleDonateModalHide}
        thankYouModal={props.showModal}
        visitor={cookie.load("visitor")}
      />

      <ThankYouModal show={props.modal} hide={props.hideModal} />
    </Container>
  );
};

export default HomeScreen;
