import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";

const ShareModal = (props) => {
  return (
    <Modal show={props.show} backdrop="static" centered onHide={props.hide}>
      <Modal.Header closeButton>
        <Modal.Title id="share-modal-title">SHARE</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">

      <EmailShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <EmailIcon size={40} round={false} borderRadius={5} />
        </EmailShareButton>

        <FacebookShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <FacebookIcon size={40} round={false} borderRadius={5} />
        </FacebookShareButton>

        <FacebookMessengerShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <FacebookMessengerIcon size={40} round={false} borderRadius={5} />
        </FacebookMessengerShareButton>

        <HatenaShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <HatenaIcon size={40} round={false} borderRadius={5} />
        </HatenaShareButton>

        <InstapaperShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <InstapaperIcon size={40} round={false} borderRadius={5} />
        </InstapaperShareButton>

        <LineShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <LineIcon size={40} round={false} borderRadius={5} />
        </LineShareButton>

        <LinkedinShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <LinkedinIcon size={40} round={false} borderRadius={5} />
        </LinkedinShareButton>

        <LivejournalShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <LivejournalIcon size={40} round={false} borderRadius={5} />
        </LivejournalShareButton>

        <MailruShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <MailruIcon size={40} round={false} borderRadius={5} />
        </MailruShareButton>

        <OKShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <OKIcon size={40} round={false} borderRadius={5} />
        </OKShareButton>

        <PinterestShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <PinterestIcon size={40} round={false} borderRadius={5} />
        </PinterestShareButton>

        <PocketShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <PocketIcon size={40} round={false} borderRadius={5} />
        </PocketShareButton>

        <RedditShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <RedditIcon size={40} round={false} borderRadius={5} />
        </RedditShareButton>

        <TelegramShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <TelegramIcon size={40} round={false} borderRadius={5} />
        </TelegramShareButton>

        <TumblrShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <TumblrIcon size={40} round={false} borderRadius={5} />
        </TumblrShareButton>

        <TwitterShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <TwitterIcon size={40} round={false} borderRadius={5} />
        </TwitterShareButton>

        <WhatsappShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <WhatsappIcon size={40} round={false} borderRadius={5} />
        </WhatsappShareButton>

        <WorkplaceShareButton
          url={props.url}
          className="mr-2 mb-1"
        >
          <WorkplaceIcon size={40} round={false} borderRadius={5} />
        </WorkplaceShareButton>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.hide}>
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareModal;
